import React from 'react';

import { Link } from 'gatsby';
import {
  MenuIcon,
  WrenchScrewdriverIcon,
} from 'lib/icons';

const links = ['/', '/cipher', '/lor', '/AI-crypto', '/AI-football'];
const labels = ['Home', 'Cipher', 'L||R', 'AI Crypto', 'AI Football'];

function Header() {
  const [hidden, setHidden] = React.useState(true);
  const url = typeof window !== 'undefined' ? window.location.pathname : '';

  const handleClick = () => {
    setHidden(!hidden);
  };

  return (
    <div className='sticky top-0 z-50 bg-black opacity-90 h-20 flex justify-between items-start md:items-center space-x-5'>
      <div className='self-center'>
        <WrenchScrewdriverIcon className='h-8 w-8 text-amber-600 ml-10' />
      </div>

      <div
        className={`flex flex-col ${
          hidden ? 'hidden' : ''
        } md:flex md:flex-row bg-black justify-end items-end md:items-start rounded-lg md:justify-between md:items-center font-semibold text-sm md:text-lg `}>
        {links.map((link, index) => (
          <Link
            key={index}
            to={link}
            className={`${
              link === url ? 'text-amber-500 border-opacity-100' : 'text-white border-opacity-0'
            } p-4 border-b-2 border-amber-500 hover:border-opacity-100 hover:text-amber-500 duration-200 cursor-pointer`}>
            {labels[index]}
          </Link>
        ))}
      </div>
      <div className='flex md:hidden self-center justify-end' onClick={handleClick}>
        <MenuIcon className='h-8 w-8 text-amber-600 mr-5' />
      </div>
      <div className='hidden md:flex'></div>
    </div>
  );
}

export default Header;
