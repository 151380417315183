import React from 'react';

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className='flex items-center justify-between justify-center'>
      <div className='px-4 mx-auto text-center'>
        <div className='text-sm text-gray-500 font-semibold py-1'>Copyright © {year} - Sang PHAM</div>
      </div>
    </div>
  );
}

export default Footer;
