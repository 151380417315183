import React, { ReactNode } from 'react';

import Footer from 'layouts/components/Footer';
import Header from 'layouts/components/Header';

function MainLayout({ children }: { children: ReactNode }) {
  return (
    <div className='flex flex-col space-y-10 justify-between'>
      <Header />
      <div className='h-full min-h-screen w-full min-w-screen'>{children}</div>
      <Footer />
    </div>
  );
}

export default MainLayout;
